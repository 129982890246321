export const auth_SignIn = ``

export const auth_SignUp = ``

export const auth_ResetPassword = `
body#id-rlt .cls-resetpwd h1,
body#id-rlt .cls-resetpwd p {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}
body#id-rlt .cls-resetpwd span {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
  display: inline-block;
}`

export const auth_Page404 = ``

export const auth_Page500 = ``

export const auth_Page403 = ``

export const spacePlanning_FrictionlessDetailedView = ``

export const auth_ForgetPassword = `
body#id-rlt .cls-forgotpwd h1,
body#id-rlt .cls-forgotpwd p {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}
body#id-rlt .cls-forgotpwd span {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
  display: inline-block;
}
`

export const auth_ProfileUpdate = `

`

export const SassDashboard = ``

export const dashboard = `
body#id-rlt .cls-storelisttable-heading {
  transform: rotate(0deg) scaleX(-1) !important;
  display: inline-block;
}

body#id-rlt .cls-dashboard .cls-main-tab .MuiTabs-indicator {
  width: 0 !important;
}
body#id-rlt .cls-dashboard .cls-tab-ui-label,
body#id-rlt .cls-dashboard .cls-last-txt,
body#id-rlt .cls-dashboard .cls-last-time,
body#id-rlt .cls-dashboard button .cls-StyledSyncedButton-text,
body#id-rlt .cls-dashboard div[class*="makeStyles-cardStyles-support"] h6[class*="makeStyles-headerValue-support"] {
  transform: rotate(0deg) scaleX(-1) !important;
}
body#id-rlt .cls-dashboard div[class*="makeStyles-cardStyles-support"] .cls-common-revenue-kit {
  transform: rotate(0deg) scaleX(-1) !important;
}
body#id-rlt .cls-dashboard .cls-subHeader-kit h3,
body#id-rlt .cls-dashboard .cls-subHeader-kit .cls-common-revenue-kit,
body#id-rlt .cls-dashboard .cls-barchart-kit h4,
body#id-rlt .cls-dashboard div[class*="makeStyles-boxStyle-support"] .MuiChip-label
{
  transform: rotate(0deg) scaleX(-1) !important;
}
body#id-rlt .cls-dashboard div[class*="makeStyles-cardStyles-support"] h4 {
  transform: rotate(0deg) scaleX(-1) !important;
  padding-right: 16px;
}
body#id-rlt .cls-dashboard div[class*="makeStyles-cardStyles-support"] .cls-pieChart-head,
body#id-rlt .cls-dashboard div[class*="makeStyles-cardStyles-support"] .cls-pieChartList-head,
body#id-rlt .cls-dashboard div[class*="makeStyles-cardStyles-support"] ul[class*="makeStyles-pieChartListbox-support"],
body#id-rlt .cls-dashboard div[class*="makeStyles-cardStyles-support"] .cls-deviceTable-Kit th,
body#id-rlt .cls-dashboard .cls-subHeader-kit h3,
body#id-rlt .cls-dashboard .cls-subHeader-kit p,
body#id-rlt .cls-dashboard h4.MuiTypography-h4
{
      transform: rotate(0deg) scaleX(-1) !important;
      text-align: right;
}
body#id-rlt .cls-dashboard div[class*="makeStyles-cardStyles-support"] span[class*="makeStyles-deviceTableTitle-support"]
{
     transform: rotate(0deg) scaleX(-1) !important;
      text-align: right;
      display: flex;
      width: fit-content;
}


body#id-rlt
  .cls-dashboard
  .cls-revenu-filter-days
  .MuiChip-label.MuiChip-labelSmall {
  transform: unset !important;
  display: inline-block;
  width: fit-content;
}
body#id-rlt #simple-menu1-revenufilter {
  transform: rotate(0deg) scaleX(-1) !important;
}

body#id-rlt #simple-menu1-revenufilter {
  transform: rotate(0deg) scaleX(-1) !important;
}
body#id-rlt #simple-menu1-revenufilter div[role="radiogroup"] {
  transform: rotate(0deg) scaleX(-1) !important;
}
body#id-rlt
  #simple-menu1-revenufilter
  div[role="radiogroup"]
  label.MuiFormControlLabel-root
  span.MuiTypography-body1 {
  transform: rotate(0deg) scaleX(-1) !important;
  display: inline-block;
}

body#id-rlt .cls-selected-images-label {
transform: rotate(0deg) scaleX(-1) !important;
display: inline-block;

}

`
export const content_countries = `
body#id-rlt .cls-common-cf-glb-kit .cls-common-cf-txt-kit,
body#id-rlt .cls-content-mdl-heading-kit,
body#id-rlt .cls-CustomizedBreadCrumb-BreadcrumbsValue1,
body#id-rlt .cls-CustomizedBreadCrumb-BreadcrumbsValue2 {
  transform: rotate(0deg) scaleX(-1) !important;
  display: inline-block;
}
body#id-rlt .cls-common-cf-glb-kit label.MuiFormLabel-root {
  transform: rotate(0deg) scaleX(-1) !important;
  left: unset !important;
  width: 100% !important;
  right: -244px !important;
  text-align: right !important;
  font-size: 12px !important;
}

body#id-rlt .cls-common-cf-glb-kit .cls-checkbox-svg-kit,
body#id-rlt .cls-common-cf-glb-kit .MuiFormHelperText-root.Mui-error,
body#id-rlt .cls-common-cf-glb-kit .MuiFormGroup-root h6.MuiTypography-h6,
body#id-rlt .cls-common-cf-glb-kit .cls-make-default-txt-kit,
body#id-rlt .cls-common-cf-glb-kit .MuiFormControlLabel-label {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}

body#id-rlt
  .cls-common-cf-glb-kit
  .cls-checkbox-svg-kit
  .MuiFormControlLabel-label.MuiTypography-body1 {
  transform: unset !important;
}
`

export const content_usStates = ``

export const content_currencies = ``

export const content_gender = ``

export const content_canadianProvincesList = ``

export const content_languages = ``

export const content_unitsOfMeasure = ``

export const content_barcodeTypes = ``

export const content_tax = ``

export const masterData_locations = `
body#id-rlt .cls-createlocations p,
body#id-rlt .cls-createlocations h5,
body#id-rlt .cls-createlocations span,
body#id-rlt .cls-createlocations button,
body#id-rlt .cls-createlocations p[class*="Mui-error"],
body#id-rlt .cls-createlocations div[class*="makeStyles-Phonenumberouter-support"] p {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}
body#id-rlt
  .cls-createlocations
  div[class*="react-tel-input"]
  input[type="tel"] {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
  padding-right: 51px;
}
body#id-rlt .cls-createlocations-geography label {
  transform: rotate(0deg) translate(10px, -10px) scaleX(-1) !important;
  transform-origin: center center !important;
}


body#id-rlt .cls-operatinghours input[type="time"],
body#id-rlt .cls-operatinghours div[class*="makeStyles-displayTextStyles-support"],
body#id-rlt .cls-operatinghours div[class*="makeStyles-daysAndHoursStyles-support"],
body#id-rlt .cls-operatinghours div[class*="makeStyles-columnWidth-support"] {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}

body#id-rlt .cls-locationtree #id b {
  transform: rotate(0deg) scaleX(-1) !important;
  display: inline-block !important;
}
body#id-rlt .cls-locationtree div[class*="MuiSelect-select"],
body#id-rlt .cls-locationtree div[class*="MuiMenu-paper"] ul > li,
body#id-rlt .cls-locationtree h5.MuiTypography-root.MuiTypography-h5,
body#id-rlt .cls-locationtree input,
body#id-rlt .cls-locationtree p {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}
body#id-rlt .cls-locationtree div[class*="makeStyles-locSizeWidth-support"] label {
  transform: translate(55px, -6px) rotate(0deg) scaleX(-1) !important;
}
body#id-rlt .cls-locationtree .ddl-locationtype {
  transform: translate(150px, 10px) rotate(0deg) scaleX(-1) !important;
}

body#id-rlt .cls-locationservice p,
body#id-rlt .cls-locationservice div[class*="MuiSelect-select"],
body#id-rlt .cls-locationservice div[class*="MuiMenu-paper"] ul > li,
body#id-rlt
  .cls-locationservice
  div[class*="makeStyles-scrollWrap-support"]
  ul
  > li
  body#id-rlt
  .cls-locationservice
  span.MuiListItemText-primary.MuiTypography-body1 {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}

body#id-rlt
  .cls-locationattribute
  div[class*="makeStyles-textFiled-support"]
  input[class*="MuiInputBase-input"],
body#id-rlt
  .cls-locationattribute
  div[class*="makeStyles-textFiled-support"]
  p[class*="Mui-error"] {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}

body#id-rlt .cls-locationattribute div[class*="makeStyles-listProperty-support"] {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
  flex-direction: row-reverse;
}
body#id-rlt .cls-locationattribute-table span {
  transform: rotate(0deg) scaleX(-1) !important;
}

body#id-rlt .cls-locationaddress h4,
body#id-rlt .cls-locationaddress h5,
body#id-rlt .cls-locationaddress h3,
body#id-rlt .cls-locationaddress p {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}
body#id-rlt
  .cls-locationaddress
  div[class*="react-tel-input"]
  input[type="tel"] {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
  padding-right: 51px;
}

body#id-rlt .cls-opendate div[class*="MuiGrid-root"] h6,
body#id-rlt .cls-opendate div[class*="MuiGrid-root"] p {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}
body#id-rlt .cls-opendate h5[class*="MuiTypography-h5"],
body#id-rlt .cls-opendate input {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}
body#id-rlt .cls-opendate .cls-calender-date-native {
  margin-bottom: 10px;
}

body#id-rlt .cls-operatinghours div[class*="MuiGrid-root"] h6 {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}

body#id-rlt .cls-locationtype div[class*="MuiGrid-root"] h6,
body#id-rlt .cls-locationtype div[class*="MuiGrid-root"] p {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}

body#id-rlt .cls-locationservices,
body#id-rlt .cls-locationservices div[class*="MuiGrid-root"] ul > li,
body#id-rlt .cls-locationservices div[class*="MuiGrid-root"] p {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}
// body#id-rlt label[class*="cls-location-switch"] span {
//   transform: rotate(0deg) scaleX(-1) !important;
// }

body#id-rlt label[class*="cls-location-switch"] span.MuiFormControlLabel-label {
  transform: rotate(0deg) scaleX(-1) !important;
}

body#id-rlt .cls-locationservice div[class*="MuiListItemText-root"] span {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}
body#id-rlt
  div[class*="cls-locationattribute-kit"]
  div[class*="MuiCardHeader-content"]
  span,
body#id-rlt div[class*="cls-locationattribute-kit"] p,
body#id-rlt
  div[class*="cls-locationattribute-kit"]
  div[class*="Mui-error"]
  p,
body#id-rlt div[class*="cls-locationattribute-kit"] h6 {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}
body#id-rlt
  div[class*="cls-products-kit"]
  div[class*="MuiCardHeader-content"]
  span
  b {
  transform: rotate(0deg) scaleX(-1) !important;
  display: inline-block !important;
}
body#id-rlt p[class*="cls-lastupdate"] {
  transform: rotate(0deg) scaleX(-1) !important;
  color: #000 !important;
  text-align: left !important;
}`

export const masterData_locations_GroupLocation_GroupLocationsList = `
body#id-rlt .cls-createlocationgroup p,
body#id-rlt .cls-createlocationgroup textarea {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}
body#id-rlt .cls-locationlookup h4,
body#id-rlt .cls-locationlookup h5,
body#id-rlt .cls-locationlookup div[class*="rst__rowLabel"] {
  transform: rotate(0deg) scaleX(-1) !important;
  display: inline-block !important;
  text-align: right !important;
}
body#id-rlt
  .cls-locationlookup
  #id-detailedTransactionReport-common.MuiSelect-selectMenu
  b,
body#id-rlt .cls-locationlookup #demo-simple-select-outlined {
  transform: rotate(0deg) scaleX(-1) !important;
  display: inline-block !important;
  text-align: right !important;
}
body#id-rlt .cls-editlocationgroup h2,
body#id-rlt .cls-editlocationgroup p,
body#id-rlt .cls-editlocationgroup textarea,
body#id-rlt .cls-editlocationgroup span[class*="MuiButton-startIcon"] {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}
`

export const masterData_Hierarchy = `
body#id-rlt .cls-hierarchyeditorheader,
body#id-rlt .cls-hierarchyeditor div[class*="rst__rowLabel"],
body#id-rlt .cls-hierarchynode h2,
body#id-rlt .cls-hierarchynode div[class*="MuiInputBase-formControl"] input,
body#id-rlt .cls-hierarchynode p {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}
body#id-rlt .cls-hierarchynode div[class*="makeStyles-textFieldInput-support"] label {
  transform: translate(48px, -6px) rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}
`

export const masterData_HierarchyMapping_index = `
body#id-rlt .cls-hierarchymappingheader {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}`

export const frictionlessStore_deviceConfig_ConfigurePos_index = ``

export const frictionlessStore_deviceConfig_ConfigurePos_Pos = ``

export const frictionlessStore_deviceConfig_ConfigurePos_CreatePos_index = ``

export const frictionlessStore_deviceConfig_ConfigurePos_PosDetailed_index = ``

export const masterData_products_ProductAttribute_index = `
body#id-rlt .cls-createattributedialog h4,
body#id-rlt .cls-createattributedialog h5,
body#id-rlt .cls-createattributedialog p,
body#id-rlt .cls-createattributedialog span,
body#id-rlt .cls-createattributedialog ul > li > span {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}

body#id-rlt .cls-dropdown-singleselect-kit {
  transform: rotate(0deg) scaleX(-1) !important;
  display: inline-block !important;
}

body#id-rlt
  div[role="presentation"].MuiAutocomplete-popper:has(> .MuiAutocomplete-paper
    ul#id-pb-barcodetype-dd-kit-popup) {
  transform: rotate(0deg) scaleX(-1) !important;
  margin: auto !important;
  right: 0 !important;
  bottom: 0 !important;
  top: 0px !important;
  left: 0px !important;
  height: fit-content !important;
  position: fixed !important;
  box-shadow: 0px 0px 8px 1110px #00000050 !important;
  max-width: 300px;
}

body#id-rlt
  div[role="presentation"].MuiAutocomplete-popper
  .MuiAutocomplete-paper
  ul#id-pb-barcodetype-dd-kit-popup
  li {
  text-align: right !important;
  display: block !important;
}

body#id-rlt
  div[role="presentation"].MuiAutocomplete-popper:has(> .MuiAutocomplete-paper
    ul#id-pb-taxCode-dd-kit-popup) {
  transform: rotate(0deg) scaleX(-1) !important;
  margin: auto !important;
  right: 0 !important;
  bottom: 0 !important;
  top: 0px !important;
  left: 0px !important;
  height: fit-content !important;
  position: fixed !important;
  box-shadow: 0px 0px 8px 1110px #00000050 !important;
  max-width: 300px;
}

body#id-rlt
  div[role="presentation"].MuiAutocomplete-popper
  .MuiAutocomplete-paper
  ul#id-pb-taxCode-dd-kit-popup
  li {
  text-align: right !important;
  display: block !important;
}


`

export const masterData_products_ProductsList = `
body#id-rlt .cls-productslist span.cls-plain-text-ui-kit {
  transform: rotate(0deg) scaleX(-1) !important;
  display: inline !important;
}
body#id-rlt .cls-tablecontainer-paging {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}
body#id-rlt
  .cls-base-dropdown-kit
  #id-header-dropdownkit-kit.MuiSelect-selectMenu {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}
body#id-rlt .cls-media-common-txt {
  transform: rotate(0deg) scaleX(-1) !important;
}
body#id-rlt .cls-training-products .cls-submain-tab .cls-tab-ui-label {
  transform: rotate(0deg) scaleX(-1) !important;
}

body#id-rlt .cls-training-products .cls-submain-tab .MuiTabs-indicator {
  left: unset !important;
}
body#id-rlt .cls-training-products .cls-plain-text-ui-kit {
  transform: rotate(0deg) scaleX(-1) !important;
  display: inline-block;
}
body#id-rlt .cls-create-model-training .cls-plain-text-ui-kit {
  transform: rotate(0deg) scaleX(-1) !important;
  display: inline-block;
}
body#id-rlt .cls-create-model-training .cls-plain-value-ui-kit {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}
body#id-rlt .cls-modeltrainning-prefix .cls-showpercent-kit p {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}
body#id-rlt .cls-modeltrainning-prefix .cls-td-kit-5 p {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}
body#id-rlt
  .cls-model-training-landingpage
  .cls-date-picker-kit
  .rdrDateRangePickerWrapper {
  transform: rotate(0deg) scaleX(-1) !important;
}
body#id-rlt .cls-grid-ui-card.cls-card-ui-kit .k-grid-table tbody[role="presentation"] tr.k-master-row td[aria-colindex="3"] {
      width: calc(100% - 180px) !important;
}
body#id-rlt .cls-startTraining-Btn .MuiButton-endIcon {
  margin-right: 8px !important;
}
`

export const masterData_products_ProductGroupPage = `
body#id-rlt .cls-createproductgroup p,
body#id-rlt .cls-createproductgroup textarea {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}
body#id-rlt .cls-editproductgroup p,
body#id-rlt .cls-editproductgroup textarea,
body#id-rlt .cls-editproductgroup span[class*="MuiButton-startIcon"] {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}
body#id-rlt .cls-editproductgroup h2 {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
  display: inline;
}`

export const masterData_products_ProductHierarchyPage = `
body#id-rlt .cls-producthierarchyheader,
body#id-rlt .cls-producthierarchy div[class*="rst__rowLabel"],
body#id-rlt .cls-productcategorydetail h4,
body#id-rlt .cls-productcategorydetail h5,
body#id-rlt .cls-productcategorydetail p,
body#id-rlt .cls-productcategorydetail textarea {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}
body#id-rlt .cls-tablecontainer table thead tr th.MuiTableCell-head > span,
body#id-rlt .cls-tablecontainer table tbody tr td.MuiTableCell-root {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}

body#id-rlt div[class*="MuiDropzonePreviewList"] p,
body#id-rlt .cls-uploadfiles p {
  transform: rotate(0deg) scaleX(-1) !important;
}
body#id-rlt .cls-upload-title-kit {
  // transform: rotate(0deg) scaleX(-1) !important;
  display: inline-block !important;
}`

export const masterData_products_ProductsMapping_ProductMapping = `
body#id-rlt .cls-createproduct-dialog h5,
body#id-rlt .cls-createproduct-dialog h4,
body#id-rlt .cls-createproduct-dialog p {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}

body#id-rlt
  div[role="presentation"].MuiAutocomplete-popper:has(> .MuiAutocomplete-paper
    ul#id-ddl-productmapping-itemid-popup) {
  transform: rotate(0deg) scaleX(-1) !important;
  margin: auto !important;
  right: 0 !important;
  bottom: 0 !important;
  top: 0px !important;
  left: 0px !important;
  height: fit-content !important;
  position: fixed !important;
  box-shadow: 0px 0px 8px 1110px #00000050 !important;
  max-width: 300px;
}

body#id-rlt
  div[role="presentation"].MuiAutocomplete-popper
  .MuiAutocomplete-paper
  ul#id-ddl-productmapping-itemid-popup li {
  text-align: right !important;
  display: block !important;
}

body#id-rlt .ecom-card-table-header h5 {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}`

export const masterData_customers = ``

export const masterData_price = ``

export const transactions = `
body#id-rlt .cls-transactions h4.MuiTypography-root.MuiTypography-h4,
body#id-rlt .cls-transactions div[class*="MuiGrid-container"] h6,
body#id-rlt .cls-transactions div[class*="makeStyles-filter-s-support"] p,
body#id-rlt .cls-transactions div[class*="MuiTableContainer-root"],
body#id-rlt .cls-transactions div[class*="MuiTableContainer-root"] table,
body#id-rlt .cls-transactions table thead tr th,
body#id-rlt .cls-transactions table tbody tr td.MuiTableCell-body,
body#id-rlt .cls-transactions div[class*="makeStyles-storeNumberHeight-support"] span,
body#id-rlt .cls-transactions div.rdrStaticRanges span,
body#id-rlt .cls-transactions div.rdrMonthName,
body#id-rlt .cls-transactions div[class*="makeStyles-topCard-support"] {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}
body#id-rlt .cls-transactions div[class*="MuiTablePagination-root"] {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
  flex-direction: row-reverse;
}
body#id-rlt .cls-transactions .cls-totime label {
  transform: translate(65px, -5px) rotate(0deg) scaleX(-1) !important;
}
body#id-rlt .cls-transactions .cls-fromtime label {
  transform: translate(88px, -7px) rotate(0deg) scaleX(-1) !important;
}
body#id-rlt
  .cls-transactions
  div.rdrMonthAndYearWrapper,
  body#id-rlt
  .cls-transactions
  div.rdrDateDisplayWrapper,
  body#id-rlt
  .cls-transactions
  div.rdrDays,
body#id-rlt .cls-transactions div.rdrInputRanges span,
body#id-rlt .cls-transactions div.rdrWeekDays span {
  transform: rotate(0deg) scaleX(-1) !important;
}
body#id-rlt .cls-transactions .cls-CheckCircleIcon {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: justify !important;
}
body#id-rlt .cls-transactions-dialog h2,
body#id-rlt .cls-transactions-dialog h4,
body#id-rlt .cls-transactions-dialog table thead tr th,
body#id-rlt .cls-transactions-dialog table tbody tr th,
body#id-rlt .cls-transactions-dialog table tbody tr td {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}`

export const trainingProducts = `
body#id-rlt .cls-syncheader {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}

body#id-rlt .cls-trainingproducts label {
  transform: translate(138px, 10px) rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}
body#id-rlt .cls-Augmentation .MuiTypography-h6,
body#id-rlt .cls-Augmentation .MuiTypography-body1,
body#id-rlt .cls-Augmentation .MuiTypography-body2,
body#id-rlt .cls-Augmentation h6 {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}
`

export const trainingProducts_create = `
body#id-rlt .cls-trainingproductscreate h5,
body#id-rlt .cls-trainingproductscreate p {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}
body#id-rlt
  .cls-trainingproductscreate
  #id-detailedTransactionReport-common.MuiSelect-selectMenu
  b {
  transform: rotate(0deg) scaleX(-1) !important;
  display: inline-block !important;
  text-align: right !important;
}`

export const userManagement_users = `
body#id-rlt .cls-user-view-page-detail .cls-user-textField-errorMsg p {
    transform: rotate(0deg) scaleX(-1) !important;
    text-align: right !important;
}

body#id-rlt .cls-user-view-page-detail div[class*="makeStyles-autoCompleteHeight-support"] p {
    transform: rotate(0deg) scaleX(-1) !important;
    text-align: right !important;
}

body#id-rlt .cls-user-view-page-detail div[class*="makeStyles-Phonenumberouter-support"] p {
    transform: rotate(0deg) scaleX(-1) !important;
    text-align: right !important;
}

body#id-rlt .cls-set-button  {
   transform: rotate(0deg) scaleX(-1) !important;
}
body#id-rlt #user-create-Edit input.search-box {
      transform: rotate(0deg) scaleX(1) !important;
      text-align: right;
}
body#id-rlt #user-create-Edit .react-tel-input .country-list .country-name {
      transform: rotate(0deg) scaleX(1) !important;
      display: inline-block;
}
body#id-rlt #user-create-Edit .react-tel-input .country-list .dial-code {
      transform: rotate(0deg) scaleX(1) !important;
      display: inline-block;
}
`

export const userManagement_rolePermission_rolePermissionList_index = `
body#id-rlt .cls-main-global-table-kit .cls-item-selected {
  transform: rotate(0deg) scaleX(-1) !important;

}`

export const userManagement_permission_index = ``

export const settings_productcatalog_index = ``

export const settings_ProductCatalogAdd_index = ``

export const settings_productCatalogEdit_ProductCatalogEdit = ``

export const settings_taxconfig_taxConfiglist = ``

export const settings_avalaraconfig_AvalaraConfigList = ``

export const settings_avalaraconfig_AvalaraCommon = ``

export const settings_taxconfig_taxConfigAdd_index = ``

export const settings_taxconfig_taxConfigEdit_index = ``

export const userManagement_rolePermission_index = `
body#id-rlt
  .cls-user-view-page-detail
  .MuiGrid-container
  .MuiCard-root
  .MuiCardHeader-root
  .MuiCardHeader-title,
body#id-rlt .cls-user-view-page-detail .cls-common-title,
body#id-rlt .cls-user-view-page-detail .MuiGrid-container .cls-kit-crc-text-cmn,
body#id-rlt
  .cls-user-view-page-detail
  .MuiGrid-container
  div[class*="makeStyles-borderRight-support"]
  .MuiGrid-root,
body#id-rlt .cls-user-view-page-detail p[class*="makeStyles-header-support"] {
  transform: rotate(0deg) scaleX(-1) !important;
  width: fit-content;
}
body#id-rlt .cls-user-view-page-detail .cls-common-title {
  display: inline-block;
}
body#id-rlt .cls-user-view-page-detail h5[class*="makeStyles-textHeader-support"] {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}
body#id-rlt
  .cls-user-view-page-detail
  div[class*="makeStyles-Phonenumberouter-support"]
  input[type="tel"] {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
  padding-right: 51px;
}
body#id-rlt .cls-user-view-page-detail p[class*="makeStyles-noteslabel-"] {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: left !important;
}
body#id-rlt .cls-user-view-page-detail .cls-noteslabel {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: left !important;
}
body#id-rlt .cls-user-view-page-detail .flag-dropdown {
  transform: rotate(0deg) scaleX(-1) !important;
}
body#id-rlt .cls-user-view-page-detail .cls-input-otp-kit {
  transform: rotate(0deg) scaleX(-1) !important;
}
body#id-rlt .cls-user-view-page-detail .MuiAvatar-circle p {
  transform: rotate(0deg) scaleX(-1) !important;
}
body#id-rlt
  .cls-user-view-page-detail
  div[class*="makeStyles-chip"]
  .MuiChip-label {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
  display: inline-block;
}
body#id-rlt
  .cls-user-view-page-detail
  .cls-chip-label
  .MuiChip-label {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
  display: inline-block;
}
body#id-rlt .cls-user-view-page-detail span.cls-common-text-value {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
  display: inline-block;
}

body#id-rlt
  div[role="presentation"]:has(> .cls-adm-dropdownfilter-1-common-text) {
  transform: unset !important;
  margin: auto !important;
  right: 0 !important;
  bottom: 0 !important;
  top: 0px !important;
  left: 0px !important;
  height: fit-content !important;
}

body#id-rlt div[role="presentation"] .cls-adm-dropdownfilter-1-common-text {
  z-index: 999;
}

body#id-rlt .cls-adm-dropdownfilter-1-common-text li[role="option"],
body#id-rlt .cls-adm-dropdownfilter-1-common-text .MuiAutocomplete-noOptions {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}

body#id-rlt .cls-adm-dropdownfilter-1-common-text ul[role="listbox"] {
  text-align: right;
  display: flex;
  flex-direction: column;
}
body#id-rlt .cls-adm-dropdownfilter-1-common-text li[role="option"] {
  display: flex;
  flex-direction: column;
  text-align: left !important;
}

`

export const userManagement_rolePermissionTenant_index = `
body#id-rlt
  .cls-RolePermissionTenantDetails-page
  .MuiGrid-container
  .MuiCard-root
  .MuiCardHeader-root
  .MuiCardHeader-title,
body#id-rlt
  .cls-RolePermissionTenantDetails-page
  .MuiGrid-container
  .cls-kit-crc-text-cmn,
body#id-rlt
  .cls-RolePermissionTenantDetails-page
  .MuiGrid-container
  div[class*="makeStyles-borderRight-"]
  .MuiGrid-root,
body#id-rlt
  .cls-RolePermissionTenantDetails-page
  p[class*="makeStyles-header-support"] {
  transform: rotate(0deg) scaleX(-1) !important;
  width: fit-content;
}

`

export const system = ``

export const editProfile = `

body#id-rlt .cls-ProfileUpdatepage-kit #id-chip-plain-text span,
body#id-rlt .cls-ProfileUpdatepage-kit span.cls-span-plain-text,
body#id-rlt .cls-ProfileUpdatepage-kit .cls-tab-securit span,
body#id-rlt .cls-ProfileUpdatepage-kit .cls-tab-genrl span {
  transform: rotate(0deg) scaleX(-1) !important;
  display: inline-block;
}

body#id-rlt
  .cls-ProfileUpdatepage-kit
  .cls-under-wrapper-form
  h5[class*="makeStyles-textHeader-support"] {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
  display: flex !important;
  flex-direction: row-reverse !important;
}

body#id-rlt .cls-ProfileUpdatepage-kit .MuiTabs-indicator {
  display: none !important;
}
body#id-rlt .cls-ProfileUpdatepage-kit .cls-tab-securit.Mui-selected,
body#id-rlt .cls-ProfileUpdatepage-kit .cls-tab-genrl.Mui-selected {
  border-bottom: 2px solid var(--primaryColor);
}

body#id-rlt .cls-SecuritySetting-page span.cls-span-plain-text {
  transform: rotate(0deg) scaleX(-1) !important;
  display: inline-block;
}
body#id-rlt .cls-SecuritySetting-page .MuiFormHelperText-contained.Mui-error {
  transform: rotate(0deg) scaleX(-1) !important;
  display: inline-block !important;
  text-align: right !important;
}


body#id-rlt .cls-ProfileUpdatepage-kit .cls-under-wrapper-form .flag-dropdown {
  transform: rotate(0deg) scaleX(-1) !important;
}
body#id-rlt
  .cls-ProfileUpdatepage-kit
  .cls-under-wrapper-form
  div[class*="makeStyles-Phonenumberouter-support"]
  input[type="tel"] {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
  padding-right: 51px;
}

body#id-rlt
  .cls-ProfileUpdatepage-kit
  .cls-under-wrapper-form
  p[class*="makeStyles-noteslabel-"] {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: left !important;
}

body#id-rlt
  .cls-ProfileUpdatepage-kit
  .cls-under-wrapper-form
  .cls-noteslabel {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: left !important;
}

body#id-rlt
  .cls-ProfileUpdatepage-kit
  .cls-under-wrapper-form
  span.cls-span-plain-text {
}


`

export const settings = ``

export const help = ``

export const support = ``

export const privacy = ``

export const settings_PaymentGatewayTerminal = `
body#id-rlt .cls-PaymentGatewayTerminal-page span.cls-plain-spg-txt {
  transform: rotate(0deg) scaleX(-1) !important;
  display: inline-block;
}
body#id-rlt .cls-PaymentGatewayTerminal-page span.cls-span-plain-dp-text {
  transform: rotate(0deg) scaleX(-1) !important;
  display: inline-block;
}
body#id-rlt
  .cls-PaymentGatewayTerminal-page
  ul[class*="makeStyles-gridList-support"]
  p.MuiTypography-body1,
body#id-rlt .cls-PaymentGatewayTerminal-page h3.MuiTypography-h3,
body#id-rlt .cls-PaymentGatewayTerminal-page .MuiChip-label {
  transform: rotate(0deg) scaleX(-1) !important;
}

body#id-rlt .cls-PaymentGatewayTerminal-page h3.MuiTypography-h3,
body#id-rlt .cls-PaymentGatewayTerminal-page input[type="text"] {
  text-align: right !important;
}

body#id-rlt .cls-PaymentGatewayTerminal-page #outlined-basic-helper-text {
  text-align: left !important;
}

body#id-rlt .cls-footer-copy-txt,
body#id-rlt .cls-PaymentGatewayTerminal-page .cls-Secretkeycode-kit-label,
body#id-rlt .cls-PaymentGatewayTerminal-page #outlined-basic-helper-text,
body#id-rlt .cls-PaymentGatewayTerminal-page .cls-option-tab-pay,
body#id-rlt .cls-PaymentGatewayTerminal-page .cls-pay-setdefault {
  transform: rotate(0deg) scaleX(-1) !important;
  display: inline-block;
}
body#id-rlt .MuiFormHelperText-marginDense {
    transform: rotate(0deg) scaleX(-1) !important;
    display: flex;
    }
`

export const settings_PaymentGatewayMobile = ``

export const liveMonitoring_Devices = `
body#id-rlt .cls-liveMonitoring-Devices .MuiGrid-root {
  transform: rotate(0deg) scaleX(-1) !important;
}
body#id-rlt .cls-liveMonitoring-Devices-Details h4.MuiTypography-h4 {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}
body#id-rlt .cls-liveMonitoring-Devices-Details td:not(td.cls-td-kit-0) {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}
body#id-rlt .cls-td-kit-0 .cls-disabledBox-kit div {
    transform: unset !important;
}
body#id-rlt .cls-liveMonitoring-Devices-Details .device-labels {
  transform: rotate(0deg) scaleX(-1) !important;
}
body#id-rlt .cls-liveMonitoring-Devices-Cards .MuiGrid-item {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}
body#id-rlt .cls-liveMonitoring-search-bar-text .MuiGrid-item {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}
body#id-rlt .cls-liveMonitoring-search-bar-text .MuiCheckbox-colorPrimary.Mui-checked {
  transform: rotate(0deg) scaleX(-1) !important;
}
body#id-rlt .cls-liveMonitoring-Devices-Cards .pnumber {
  transform: rotate(0deg) scaleX(-1) !important;
}
body#id-rlt .cls-liveMonitoring-search-bar-text .MuiInputBase-input {
  transform: unset !important;
}
body#id-rlt .cls-liveMonitoring-search-bar-text .MuiButtonBase-root {
  left:10px !important;
  right:unset !important;
}
`

export const termsOfService = ``

export const masterData_locations_CreateLocations = `
body#id-rlt .cls-createlocations h5[class*="makeStyles-inputFieldText-support"]  {
    transform: rotate(0deg) scaleX(-1) !important;
    text-align: right;
    }`

export const masterData_locations_GroupLocation_LocationGroupCreatePage = ``

export const masterData_products_createGroupModel_ProductGroupCreatePage = `
`

export const masterData_locations_LocationDetailedPage_index = ``

export const masterData_customers_CustomerDetailedPage = ``

export const masterData_price_priceDetailedPage = ``

export const masterData_products_GroupDetail_ProductGroupDetailedPage = ``

export const masterData_customers_AddNewAddress = ``

export const masterData_customers_CreateCustomers = ``

export const masterData_price_CreatePrice = ``

export const masterData_products_ProductCreatePage = `
body#id-rlt .cls-productcreate p,
body#id-rlt .cls-productcreate h5 {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}

body#id-rlt .cls-productedit p,
body#id-rlt .cls-productedit h5 {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}

body#id-rlt .cls-globalimportexport .cls-dkp-progress-wrapper {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
  margin-right: 10rem;
}
body#id-rlt .cls-globalimportexport span,
body#id-rlt .cls-globalimportexport #chooseExportId.MuiSelect-selectMenu,
body#id-rlt .cls-globalimportexport #formatsId.MuiSelect-selectMenu {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}
`

export const masterData_products_ProductDetailedPage_ProductDetailedPage = `
body#id-rlt .cls-media-kit span,
body#id-rlt .cls-media-kit h6,
body#id-rlt .cls-media-kit button {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}
body#id-rlt .cls-productspecifications-kit span,
body#id-rlt .cls-productspecifications-kit p {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}
body#id-rlt .cls-producthierarchy-kit span,
body#id-rlt .cls-producthierarchy-kit h4,
body#id-rlt .cls-producthierarchy-kit button {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}
body#id-rlt .cls-attributes-kit span,
body#id-rlt .cls-attributes-kit h4,
body#id-rlt .cls-attributes-kit button {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}
body#id-rlt .cls-productspecification-kit span[class*="MuiCardHeader-title"],
body#id-rlt .cls-productspecification-kit p {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
  display: inline-block !important;
}

body#id-rlt .cls-productspecification-kit p[class*="makeStyles-subLabel-support"] 
{
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
  display: inline !important;
}
body#id-rlt .cls-productspecification-kit .cls-spec-subLabel {
  transform: rotate(0deg) scaleX(1) !important;
}
body#id-rlt .cls-productspecification-kit span.cls-span-plain-dp-text {
  display: inline-block;
}

body#id-rlt .cls-hierarchyeditor .cls-hierarchy-button span {
      transform: rotate(0deg) scaleX(-1) !important;
}

body#id-rlt .cls-producthierarchy-kit .cls-hierarchy-button span {
          transform: rotate(0deg) scaleX(1) !important;
}
body#id-rlt .cls-hierarchymapping-kit .cls-hierarchyeditor .cls-hierarchy-button span {
      transform: rotate(0deg) scaleX(-1) !important;
}
body#id-rlt .cls-producthierarchy-kit .rst__rowLabel {
      transform: rotate(0deg) scaleX(-1) !important;
      text-align: right;
}
body#id-rlt .cls-attributes-kit .cls-attribute-btn span.MuiButton-label {
    transform: rotate(0deg) scaleX(1) !important;
}
body#id-rlt .cls-attributes-kit .cls-base-dropdown-kit div#demo-simple-select-outlined {
    transform: rotate(0deg) scaleX(-1) !important;
    text-align: right !important;
}

body#id-rlt .cls-attributes-kit div[class*="makeStyles-attributeList-support"] div[class*="makeStyles-textHeader-support"] h5 {
    transform: rotate(0deg) scaleX(-1) !important;
    text-align: right !important;
}

body#id-rlt .cls-attributes-kit .cls-att-value-kit {
    transform: rotate(0deg) scaleX(-1) !important;
     text-align: right !important;
}

body#id-rlt .cls-attributes-kit .cls-editor-kit .k-button-group .k-icon{
    transform: rotate(0deg) scaleX(1) !important;
     text-align: right !important;
}

body#id-rlt .cls-attributes-kit .cls-editor-kit .k-editor-content .k-iframe {
    transform: rotate(0deg) scaleX(-1) !important;
}
body#id-rlt .cls-attributes-kit .cls-editor-kit .k-content {
     text-align: right !important;
}
body#id-rlt .cls-attributes-kit .cls-multi-checklist-kit {
        transform: rotate(0deg) scaleX(-1) !important;
        display: inline-block;
}

body#id-rlt .cls-attributes-kit .cls-attribute-calender input {
    transform: rotate(0deg) scaleX(-1) !important;
    text-align: right !important;
}

`

export const masterData_products_GroupDetail = ``
export const masterData_inventory_module = `
 body#id-rlt .cls-adjustment-edit-table-page .cls-plain-text-ui-kit,
 body#id-rlt .cls-adjustmentinventoryview-page .cls-plain-text-ui-kit,
 body#id-rlt .cls-inventory-land-page .cls-plain-text-ui-kit,
 body#id-rlt .cls-inventoryAdjustment-page .cls-plain-text-ui-kit {
      transform: rotate(0deg) scaleX(-1) !important;
      display: inline-block;
    }

 body#id-rlt .cls-adjustmentinventoryview-page h4[class*="makeStyles-headerTitle-support"] span {
   display: unset;
 }

 body#id-rlt .cls-livemonitoring-page .cls-plain-text-ui-kit {
    transform: rotate(0deg) scaleX(-1) !important;
 }

 body#id-rlt .cls-translate-txt {
    transform: rotate(0deg) scaleX(-1) !important;
    text-align: end;
 }

    body#id-rlt.cls-inventory-adjument-table
      div[role="presentation"].MuiAutocomplete-popper {
      transform: unset !important;
      margin: auto !important;
      right: 0 !important;
      bottom: 0 !important;
      top: 0px !important;
      left: 0px !important;
      height: fit-content !important;
      position: fixed !important;
    }

    body#id-rlt .cls-adjustment-edit-table-page .cls-plain-text-ui-kit {
    text-align: end;
    }

    body#id-rlt div[role="presentation"].MuiAutocomplete-popper div.MuiAutocomplete-noOptions  {
      transform: rotate(0deg) scaleX(-1) !important;
      text-align: end;
    }
    body#id-rlt div[role="presentation"] ul#id-pmaj-dropdown-menu-kit-popup {
      transform: rotate(0deg) scaleX(-1) !important;
    }
    body#id-rlt div[role="presentation"] ul#id-pmaj-dropdown-menu-kit-popup li {
      display: block;
      width: 100%;
      text-align: right;
    }

    body#id-rlt.cls-inventory-adjument-table p.MuiTablePagination-caption {
  transform: rotate(0deg) scaleX(-1) !important;
}
body#id-rlt.cls-inventory-adjument-table .MuiTablePagination-input {
  transform: rotate(0deg) scaleX(-1) !important;
}

body#id-rlt.cls-inventory-adjument-table
  div[role="presentation"].MuiPopover-root
  .MuiPaper-root.MuiMenu-paper.MuiPopover-paper {
  transform: unset !important;
  margin: auto !important;
  right: 0 !important;
  bottom: 0 !important;
  top: 0px !important;
  left: 0px !important;
  height: fit-content !important;
  position: fixed !important;
  width: 300px;
  box-shadow: 0px 0px 8px 1110px #00000050 !important;
}

body#id-rlt.cls-inventory-adjument-table
  div[role="presentation"]
  .MuiTablePagination-menuItem {
  transform: rotate(0deg) scaleX(-1) !important;
}
body#id-rlt .cls-adjustment-edit-table-page div[class*="makeStyles-textFeildStyles-support"] label {
    transform: rotate(0deg) scaleX(-1) !important;
    transform-origin: top;
    top: 12px;
    left: 15px;
}
body#id-rlt .cls-inventoryCounts-page .cls-plain-text-ui-kit,
body#id-rlt .cls-modeltraining-page .cls-plain-text-ui-kit {
      transform: rotate(0deg) scaleX(-1) !important;
      display: inline-block;
}
body#id-rlt .cls-progress-augument-completed {
      transform: rotate(0deg) scaleX(-1) !important;
      text-align: left;
}
body#id-rlt .cls-progress-augument-processing{
      transform: rotate(0deg) scaleX(-1) !important;
      text-align: left;
}
body#id-rlt .cls-progress-bar-aug-wrapper{
      transform: rotate(0deg) scaleX(-1) !important;
      text-align: end;
}

body#id-rlt .cls-inventoryCounts-page h4[class*="makeStyles-headerTitle-support"] span { 
        transform: rotate(0deg) scaleX(-1) !important;
        display: unset;
}
body#id-rlt .cls-global-importExport-kit .cls-dkp-progress-wrapper h2,
body#id-rlt .cls-global-importExport-kit .cls-dkp-progress-wrapper .cls-file-state
{
     transform: rotate(0deg) scaleX(-1) !important;
}

body#id-rlt .cls-global-importExport-kit .cls-dkp-progress-wrapper .cls-dkph-trim.MuiTypography-h6{
     transform: rotate(0deg) scaleX(-1) !important;
     text-align: right;
}
body#id-rlt .cls-global-importExport-kit .cls-dk-error-wrapper{
     transform: rotate(0deg) scaleX(-1) !important;
}
body#id-rlt .cls-global-importExport-kit .cls-dk-formcontrol-1 .MuiTypography-caption,
body#id-rlt .cls-global-importExport-kit .cls-dk-formcontrol-2 .MuiTypography-caption {
     transform: rotate(0deg) scaleX(-1) !important;
     text-align: right !important;
}
body#id-rlt .cls-global-importExport-kit .cls-dk-formcontrol-1 .MuiSelect-selectMenu,
body#id-rlt .cls-global-importExport-kit .cls-dk-formcontrol-2 .MuiSelect-selectMenu {
     transform: rotate(0deg) scaleX(-1) !important;
     padding-right: 20px;
     text-align: right;
}
body#id-rlt.cls-global-importExport div[role="presentation"]
  .MuiPaper-root.MuiMenu-paper.MuiPopover-paper {
  transform: unset !important;
  margin: auto !important;
  right: 0 !important;
  bottom: 0 !important;
  top: 0px !important;
  left: 0px !important;
  height: fit-content !important;
  position: fixed !important;
  width: 300px;
  box-shadow: 0px 0px 8px 1110px #00000050 !important;
}

 body#id-rlt.cls-global-importExport div[role="presentation"] .MuiMenuItem-root {
      transform: rotate(0deg) scaleX(-1) !important;
      justify-content:end;
}
body#id-rlt .cls-main-global-table-kit .cls-showMore-code {
transform: rotate(0deg) scaleX(-1) !important;
text-align: right;
}


body#id-rlt.cls-global-importExport .cls-raised-button-file h4,
body#id-rlt.cls-global-importExport .cls-raised-button-file h6,
body#id-rlt.cls-global-importExport .cls-raised-button-file p,
body#id-rlt.cls-global-importExport .cls-raised-button-file span
{
      transform: rotate(0deg) scaleX(-1) !important;
      text-align:right;

}

body#id-rlt.cls-global-importExport cls-global-importExport-kit .cls-dkp-progress-wrapper .cls-download-txt
{
      transform: rotate(0deg) scaleX(-1) !important;

}
body#id-rlt.cls-global-importExport-kit .cls-plain-text-ui-kit {
    transform: rotate(0deg) scaleX(-1) !important;
    display: inline-block;
}
body#id-rlt.cls-global-importExport .cls-plain-text-ui-kit {
    transform: rotate(0deg) scaleX(-1) !important;
    display: inline-block;
}

.cls-unset{
    transform: unset !important;
    display: inline-block;
}
body#id-rlt.cls-global-importExport .cls-global-importExport-kit .cls-dkp-progress-wrapper .cls-download-txt
{
      transform: rotate(0deg) scaleX(-1) !important;
}
body#id-rlt.cls-global-importExport .cls-dkp-file-name,
body#id-rlt.cls-global-importExport .cls-dkp-file-size,
body#id-rlt.cls-global-importExport .cls-dkp-file-status {
          transform: rotate(0deg) scaleX(-1) !important;
          text-align: right;

}
body#id-rlt.cls-global-importExport .cls-progressTitle {
     transform: rotate(0deg) scaleX(-1) !important;
}
body#id-rlt.cls-global-importExport .cls-subtitle {
         transform: rotate(0deg) scaleX(-1) !important;
}
body#id-rlt.cls-global-importExport .cls-sync-ImportExport {
    transform: rotate(0deg) scaleX(-1) !important;
}
`
export const masterData_inventory_Report = `
body#id-rlt .cls-inventory-report-kit div[class*="makeStyles-headContent-support"] h4,
    body#id-rlt .cls-inventory-report-kit .cls-inv-report-preview h4{
  transform: rotate(0deg) scaleX(-1) !important;
}

body#id-rlt .cls-inventory-report-kit .cls-inv-errorMsg,
body#id-rlt .cls-inventory-report-kit .cls-date-picker-kit h5,
body#id-rlt .cls-inventory-report-kit div[class*="makeStyles-dropDowntextField-support"] b {
      transform: rotate(0deg) scaleX(-1) !important;
      display: flex !important;
      width: fit-content !important;
}
body#id-rlt .cls-inventory-report-kit h5[class*="makeStyles-textHeader-support"] {
          transform: rotate(0deg) scaleX(-1) !important;
          width: fit-content !important;
}
body#id-rlt ul[aria-labelledby="id-detailedTransactionReport-common"] {
    width: unset !important;
    padding: 0 !important;
}
body#id-rlt .cls-inventory-report-kit .cls-inv-report-preview table[class*="makeStyles-table-support"] th,
body#id-rlt .cls-inventory-report-kit .cls-inv-report-preview table[class*="makeStyles-table-support"] td
 {
          transform: rotate(0deg) scaleX(-1) !important;
          text-align: right;

}

body#id-rlt.cls-inventory-report p.MuiTablePagination-caption,
body#id-rlt.cls-inventory-report .MuiTablePagination-input {
  transform: rotate(0deg) scaleX(-1) !important;
}

body#id-rlt.cls-inventory-report
  div[role="presentation"].MuiPopover-root .MuiPaper-root.MuiMenu-paper.MuiPopover-paper {
  transform: unset !important;
  margin: auto !important;
  right: 0 !important;
  bottom: 0 !important;
  top: 0px !important;
  left: 0px !important;
  height: fit-content !important;
  position: fixed !important;
  width: 300px;
  box-shadow: 0px 0px 8px 1110px #00000050 !important;
}

body#id-rlt.cls-inventory-report
  div[role="presentation"]
  .MuiTablePagination-menuItem {
  transform: rotate(0deg) scaleX(-1) !important;
}
body#id-rlt.cls-inventory-report div[role="presentation"].MuiPopover-root .MuiPaper-root.MuiMenu-paper.MuiPopover-paper .cls-btnGroup-export {
    transform: rotate(0deg) scaleX(-1) !important;
    text-align: right;
    display: block;
}

`

export const status = ``

export const transactions_EditOrder_EditOrder = `
body#id-rlt .cls-transactions-firstelementdetails p,
body#id-rlt div[data-position="finalCartImages"] h6,
body#id-rlt div[data-position="finalCartImages"] span,
body#id-rlt div[data-position="orderStatusHistory"] span,
body#id-rlt div[data-position="refundHistory"] span,
body#id-rlt
  .cls-transactions-firstelementdetails
  div[class*="makeStyles-paymentInfoContainer-support"]
  p {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}
body#id-rlt div[data-position="cartSummary"] span
{
  transform: rotate(0deg) scaleX(-1) !important;
}
body#id-rlt div[data-position="cartSummary"] table tbody tr th,
body#id-rlt div[data-position="cartSummary"] table tbody tr td {
  transform: rotate(0deg) scaleX(-1) !important;
}
body#id-rlt div[data-position="cartSummary"] span.cls-editicon,
body#id-rlt div[data-position="cartSummary"] span.cls-span {
  transform: unset !important;
  padding: 2px !important;
}
body#id-rlt div[data-position="cartSummary"] span.MuiButton-label {
  transform: unset !important;
}
body#id-rlt div[data-position="finalCartImages"] table thead tr th,
body#id-rlt div[data-position="finalCartImages"] table tbody tr th,
body#id-rlt div[data-position="finalCartImages"] table tbody tr td {
  transform: rotate(0deg) scaleX(-1) !important;
}
body#id-rlt div[data-position="orderStatusHistory"] table tbody tr th,
body#id-rlt div[data-position="orderStatusHistory"] table tbody tr td {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}
body#id-rlt div[data-position="refundHistory"] table thead tr th,
body#id-rlt div[data-position="refundHistory"] table tbody tr th,
body#id-rlt div[data-position="refundHistory"] table tbody tr td {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}
`

export const tenant_index = ``

export const userManagement_users_userCreateEdit_index = ``

export const tenant_tenantCreateEdit_TenantCreateEdit = ``

export const liveMonitoring_products_ProductsList = ``

export const liveMonitoring_products_ProductDetailedPage = ``

export const liveMonitoring_devices_DevicesList = ``

export const liveMonitoring_devices_Stores_Devices = ``

export const liveMonitoring_devices_Stores = ``

export const liveMonitoring_devices_DeviceDetailedPage = ``

export const spacePlanning_PlanogramDetailedView = `
body#id-rlt .cls-planogram,
body#id-rlt .cls-planogram div[class*="MuiTableContainer-root"] table,
body#id-rlt .cls-planogram table thead tr th,
body#id-rlt .cls-planogram table tbody tr td.MuiTableCell-body {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}`

export const spacePlanning_spacePlanningList_index = ``

export const spacePlanning_spacePlanningList_FrictionlessStore_spacePlanningList = ``

export const frictionlessStore_deviceConfig_ModalConfig_index = ``

export const frictionlessStore_deviceConfig_ModalConfig_ModalConfig = ``

export const logviewer_auditLogs_AuditLog = `
body#id-rlt input[type="text"] {
  text-align: right !important;
}
body#id-rlt .cls-logviewer_auditLogs_AuditLog-page .MuiInputLabel-shrink {
  transform: translate(68px, -6px) rotate(0deg) scaleX(-1) !important;
  text-align: right;
  font-size: 12px;
}

body#id-rlt .cls-adm-dropdownfilter-auditlogs li[role="option"],
body#id-rlt .cls-adm-dropdownfilter-auditlogs .MuiAutocomplete-noOptions {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
  width: fit-content;
}

body#id-rlt input[type="text"] {
  padding-right: 6px;
}

body#id-rlt .MuiPickersBasePicker-pickerView svg {
  transform: unset !important;
}
body#id-rlt .MuiPickersBasePicker-pickerView,
body#id-rlt
  .cls-logviewer_auditLogs_AuditLog-page
  h4[class*="makeStyles-title-support"] {
  transform: rotate(0deg) scaleX(-1) !important;
}
body#id-rlt
  div[role="presentation"].MuiAutocomplete-popper:has(> .cls-adm-dropdownfilter-auditlogs) {
  transform: translate3d(182px, 216px, 0px) !important;
}

body#id-rlt
  .cls-logviewer_auditLogs_AuditLog-page
  #date-picker-inline-helper-text,
body#id-rlt
  .cls-logviewer_auditLogs_AuditLog-page
  form
  .cls-adm-dropdownfilter-1-common-inner-text
  p.MuiFormHelperText-root.Mui-error {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}


`

export const reports_detailedTransactionReport = `
body#id-rlt .cls-detailedTransactionReport-page h4.MuiTypography-h4 {
  transform: rotate(0deg) scaleX(-1) !important;
}
body#id-rlt .cls-detailedTransactionReport-page .cls-common-heading,
body#id-rlt .cls-detailedTransactionReport-page .cls-time-picker,
body#id-rlt .cls-detailedTransactionReport-page .cls-date-picker {
  transform: rotate(0deg) scaleX(-1) !important;
  display: inline-block !important;
}

body#id-rlt .cls-detailedTransactionReport-page label {
  transform: translate(70px, -6px) rotate(0deg) scaleX(-1) !important;
  font-size: 13px;
  background: white;
}

body#id-rlt .cls-detailedTransactionReport-page p.Mui-error {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}
body#id-rlt .cls-detailedTransactionReport-page .rdrDateRangePickerWrapper {
  transform: rotate(0deg) scaleX(-1) !important;
}

body#id-rlt
  .cls-detailedTransactionReport-page
  .MuiTypography-root.MuiTypography-caption {
  transform: rotate(0deg) scaleX(-1) !important;
  display: inline-block !important;
}

body#id-rlt
  .cls-detailedTransactionReport-page
  .id-detailedTransactionReport-pay-type {
  transform: rotate(0deg) scaleX(-1) !important;
}

body#id-rlt
  .cls-detailedTransactionReport-page
  #id-detailedTransactionReport-common.MuiSelect-selectMenu
  b {
  transform: rotate(0deg) scaleX(-1) !important;
  display: inline-block !important;
}

body#id-rlt
  div[role="presentation"]:has(> .cls-detailedTransactionReport-common-type) {
  transform: rotate(0deg) scaleX(-1) !important;
}
body#id-rlt .cls-detailedTransactionReport-common-type .MuiList-root {
  transform: rotate(0deg) scaleX(-1) !important;
}
body#id-rlt
  .cls-detailedTransactionReport-common-type
  .MuiList-root
  .MuiTypography-body1.MuiListItemText-primary {
  transform: rotate(0deg) scaleX(-1) !important;
  display: inline-block !important;
}
body#id-rlt .cls-detailedTransactionReport-common-type .MuiTypography-caption {
  transform: rotate(0deg) scaleX(-1) !important;
  display: inline-block !important;
}
body#id-rlt
  .cls-detailedTransactionReport-common-type
  .MuiList-root
  li.Mui-disabled {
  transform: rotate(0deg) scaleX(-1) !important;
  display: inline-block !important;
}

body#id-rlt div[role="dialog"] div[class*="MuiPickersBasePicker-container-"] {
  transform: rotate(0deg) scaleX(-1) !important;
}
body#id-rlt
  div[role="dialog"]
  div[class*="MuiDialogActions-root"]
  span[class*="MuiButton-label-"] {
  transform: rotate(0deg) scaleX(-1) !important;
  display: inline-block !important;
}
body#id-rlt .cls-detailedTransactionReport-page th {
  transform: rotate(0deg) scaleX(-1) !important;
}
body#id-rlt .cls-detailedTransactionReport-page td {
  transform: rotate(0deg) scaleX(-1) !important;
}
body#id-rlt .cls-detailedTransactionReport-page .footer-detail {
  transform: rotate(0deg) scaleX(-1) !important;
}
body#id-rlt .cls-detailedTransaction-report-kit .cls-inv-errorMsg{
    transform: rotate(0deg) scaleX(-1) !important;
      display: flex !important;
      width: fit-content !important;
}
body#id-rlt .cls-td-kit-ele p#outlined-required-helper-text {
    transform: rotate(0deg) scaleX(-1) !important;
    text-align: right !important;
}
`

export const reports_salesSummaryReport = `
body#id-rlt .cls-salesSummaryReport-page h4.MuiTypography-h4,
body#id-rlt .cls-salesSummaryReport-page h5.MuiTypography-h5 {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right;
}
body#id-rlt .cls-salesSummaryReport-page .MuiSelect-select.MuiSelect-select {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right;
}
body#id-rlt .cls-salesSummaryReport-page label {
  transform: translate(70px, -6px) rotate(0deg) scaleX(-1) !important;
  font-size: 13px;
  background: white;
}
body#id-rlt div[role="dialog"] div[class*="MuiPickersBasePicker-container-"] {
  transform: rotate(0deg) scaleX(-1) !important;
}
body#id-rlt .cls-salesSummaryReport-page p.Mui-error {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}
body#id-rlt .cls-salesSummaryReport-page .rdrDateRangePickerWrapper {
  transform: rotate(0deg) scaleX(-1) !important;
}
body#id-rlt .cls-salesSummaryReport-page .generatedDate {
  transform: rotate(0deg) scaleX(-1) !important;
  justify-content: left !important;
}

body#id-rlt .cls-salesSummaryReport-page .summary-label {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important
}
`

export const reports_scheduledReport = ``

export const reports_scheduledReport_ScheduledReportsCreateEdit = `
body#id-rlt .cls-ScheduledReportsCreateEdit-page span.cls-span-plain-text,
body#id-rlt .cls-ScheduledReportsCreateEdit-page span.cls-span-plain-dp-text {
  transform: rotate(0deg) scaleX(-1) !important;
  display: inline-block;
}
body#id-rlt .cls-ScheduledReportsCreateEdit-page p,
body#id-rlt .cls-ScheduledReportsCreateEdit-page h5.cls-plain-text {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
  display: inline-block !important;
}
body#id-rlt .cls-ScheduledReportsCreateEdit-page label {
  transform: rotate(0deg) translate(12px, -7px) scaleX(-1) !important;
  transform-origin: center center !important;
  font-size: 12px;
}
body#id-rlt
  div[role="presentation"]
  .cls-header-dropdownkit-kit
  ul[role="listbox"] {
  text-align: right;
  display: flex;
  flex-direction: column;
}
body#id-rlt
  div[role="presentation"]
  .cls-header-dropdownkit-kit
  li[role="option"] {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: center !important;
  width: 100%;
  display: inline-block;
}

body#id-rlt div[role="presentation"] .cls-header-dropdownkit-kit {
  transform: unset !important;
  margin: auto !important;
  right: 0 !important;
  bottom: 0 !important;
  top: 0px !important;
  left: 0px !important;
  height: fit-content !important;
  position: fixed !important;
  max-width: 300px;
}

`

export const alerts_Alerts = `
body#id-rlt .cls-gridTable-head {
    transform: rotate(0deg) scaleX(-1) !important;
}

body#id-rlt .cls-RoleDropdown-kit div[class*="makeStyles-selectCheckbox-support"] .MuiInputLabel-root {
    transform: rotate(0deg) scaleX(-1) !important;
    left: 60px
}

body#id-rlt .cls-RoleDropdown-kit div[class*="makeStyles-selectCheckbox-support"] #demo-mutiple-checkbox {
    transform: rotate(0deg) scaleX(-1) !important;
    padding-left: 25px;
}

body#id-rlt .cls-status-color-based {
    transform: rotate(0deg) scaleX(-1) !important;

}

body#id-rlt .cls-prd-heading {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
  display: inline-block;
}
body#id-rlt .cls-pred-text-footer {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
  display: inline-block;
}
body#id-rlt .cls-pred-icon-header-nav {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
  display: inline-block;
}
body#id-rlt .cls-pred-edit-heading {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
  display: inline-block;
}
body#id-rlt .cls-pred-edit-grid-heading {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
  display: inline-block;
}

body#id-rlt .cls-pred-edit-grid-drag-text {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
  display: inline-block;
}





`

export const alerts_NewAlert = ``

export const alerts_configuration_index = ``

export const alerts_monitor_index = ``
