import Keycloak from "keycloak-js"
import { createBrowserHistory } from "history"
import { Translation, useTranslation } from "react-i18next"
import { fetchTranslationData } from "../services/common"
import * as ServiceAPI from "@services"
import * as constantsAPI from "@constants"
import jwt_decode from "jwt-decode"
import _ from "lodash"
import { API_CM_URL } from "../utils/url"
const { APIEndPoint: { API_END_POINTS = {} } = {} } = constantsAPI || {}
const history = createBrowserHistory()

const keycloakConfig = {
  realm: process.env.REACT_APP_REALM_NAME,
  url: process.env.REACT_APP_KEYCLOAK_URL,
  clientId: process.env.REACT_APP_CLIENTID,
  "ssl-required": "external",
  "public-client": true,
  "verify-token-audience": true,
  "use-resource-role-mappings": true,
  "confidential-port": 0,
}
const _kc = new Keycloak(keycloakConfig)
/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
  const { pathname = "" } = window.location
  window["_kc"] = _kc
  console.log(window["_kc"])
  if (
    pathname === "/auth/forgetpassword" ||
    pathname === "/password/resetpassword"
  ) {
    localStorage.setItem("lang", "password")
    let langCode = new URLSearchParams(window.location.search).get("lang")
    setLanguageParam(onAuthenticatedCallback, langCode)
  } else {
    _kc
      .init({
        onLoad: "check-sso",
        silentCheckSsoRedirectUri:
          window.location.origin + "/silent-check-sso.html",
        pkceMethod: "S256",
        checkLoginIframe: false,
      })
      .then((authenticated) => {
        if (!authenticated) {
          history.push("/")
          doLogin()
        } else {
          let mydata = getToken((res) => {
            return res
          })
          let defaultRealm = "DigitKart"
          let defaultHost = "devk8s.digitkart.ai"
          const { REACT_X_KC_HOST = "", REACT_APP_REALM_NAME = "" } =
            process.env || {}

          if (REACT_APP_REALM_NAME) {
            defaultRealm = REACT_APP_REALM_NAME
          }
          if (REACT_X_KC_HOST) {
            defaultHost = REACT_X_KC_HOST
          }
          console.log("<<< user service: ", defaultRealm, defaultHost)
          localStorage.setItem("token", mydata)
          localStorage.setItem("realmId", defaultRealm)
          localStorage.setItem("kcHost", defaultHost)

          let getDataToken = getToken((res) => {
            return res
          })
          let decoded = jwt_decode(getDataToken)
          let defaultRoleStatus = false
          let roleArray = decoded.userRole
          const myfile = _.map(roleArray, (x, i) => {
            if (
              x !== undefined &&
              x.split("-")?.[0] === "default" &&
              x.split("-")?.[1] === "roles"
            ) {
              defaultRoleStatus = true
              roleArray.splice(i, 1)
            }
          })
          let myRole = _.filter(roleArray, (x) => x !== "Customer")
          /***
           * @SAAS
           */

          let isSAASRolePresent = false
          roleArray?.map((x) => {
            if (x.includes("SaaS-Site-Admin")) {
              return (isSAASRolePresent = true)
            }
          })
          if (_.includes(myRole, "SaaS-Site-Admin") || isSAASRolePresent) {
            localStorage.setItem("language_display_direction", "ltr")
            localStorage.setItem("defaultLanguage", "en")
            localStorage.setItem(
              "languages",
              JSON.stringify([
                {
                  id: "XXX",
                  name: "English",
                  code: "ENG",
                  lng: "en",
                  language_code: "en",
                  default: true,
                  display_direction: "ltr",
                },
              ])
            )
            fetchTranslationData(["en"])
              .then((res) => {
                console.log("translationData res", res)
                window["translationData"] = res
                localStorage.setItem("translationData", JSON.stringify(res))
                setTimeout(() => {
                  onAuthenticatedCallback("static1")
                }, 500)
              })
              .catch((err) => {
                history.push("/")
                doLogin()
              })
            localStorage.setItem("languageskit", JSON.stringify(["en"]))
          } else {
            /**
             * TENANT
             */
            ServiceAPI.getKCRoleData(
              API_END_POINTS.getTenantIdByName + decoded.Tenant[0]
            )
              .then((tenantResponse) => {
                let tenantData = _.get(tenantResponse, "data.data", {})
                const languages = tenantData?.language.map((itm) =>
                  itm?.language_code ? itm?.language_code : "en"
                )
                let defaultLanguage = tenantData?.language
                  ? tenantData?.language.filter((itm) => itm?.default)
                  : []
                const [_firstDefaultLanguage] = defaultLanguage
                defaultLanguage = _firstDefaultLanguage?.language_code
                let display_direction = _firstDefaultLanguage?.display_direction
                if (
                  localStorage.getItem("language_display_direction") === null
                ) {
                  localStorage.setItem(
                    "language_display_direction",
                    display_direction
                  )
                }
                localStorage.setItem("defaultLanguage", defaultLanguage)
                if (!languages?.includes(localStorage.getItem("lang"))) {
                  localStorage.setItem("lang", defaultLanguage)
                  localStorage.setItem("i18nextLng", defaultLanguage)
                  localStorage.setItem(
                    "language_display_direction",
                    display_direction
                  )
                }

                localStorage.setItem(
                  "languages",
                  JSON.stringify(tenantData?.language)
                )
                localStorage.setItem("languageskit", JSON.stringify(languages))
                fetchTranslationData(languages)
                  .then((res) => {
                    // const result = {
                    //   ...res,
                    //   en: {
                    //     ...res.en,
                    //   },
                    // }
                    window["translationData"] = res
                    localStorage.setItem("translationData", JSON.stringify(res))
                    setTimeout(() => {
                      onAuthenticatedCallback("static1")
                    }, 500)
                  })
                  .catch((err) => {
                    history.push("/")
                    doLogin()
                  })
              })
              .catch((err) => {
                history.push("/")
                doLogin()
              })
          }
        }
      })
      .catch(console.error)
  }
}

const doLogin = _kc?.login

const doLogout = () => {
  const { historyW = {} } = window
  historyW.push("/")
  setTimeout(() => {
    _kc.logout()
  }, 100)
  // _kc.logout()
}

const getToken = () => _kc.token

const isLoggedIn = () => !!_kc.token

const isAuthExpired = () => _kc.isTokenExpired()

const updateRefreshToken = () => {
  return new Promise((resolve, reject) => {
    _kc
      .updateToken()
      .success(() => {
        resolve(true)
      })
      .error(() => {
        reject(false)
      })
  })
}

const updateToken = (successCallback) =>
  _kc.updateToken(5).then(successCallback).catch(doLogin)

const getUsername = () => _kc.tokenParsed?.preferred_username

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role))

async function setLanguageParam(onAuthenticatedCallback, langCode) {
  let langListUrl = `${API_CM_URL}/api/language/getKcLanguages`
  fetch(langListUrl)
    .then((res) => {
      return res.json()
    })
    .then((data) => {
      localStorage.setItem("languages", JSON.stringify(data?.data))
      const filteredLanguage = data?.data?.find(
        (item) => item.language_code === langCode
      )
      if (filteredLanguage) {
        localStorage.setItem(
          "language_display_direction",
          JSON.stringify(filteredLanguage.display_direction)
        )
      }
    })
    .catch((error) => {
      console.error(error)
      history.push("/")
      doLogin()
    })
  localStorage.setItem("defaultLanguage", "en")
  localStorage.setItem("i18nextLng", langCode)
  localStorage.setItem("lang", langCode)
  localStorage.setItem("language_code", langCode)
  fetchTranslationData([langCode])
    .then((res) => {
      console.log("translationData res", res)
      window["translationData"] = res
      localStorage.setItem("translationData", JSON.stringify(res))
      setTimeout(() => {
        onAuthenticatedCallback("static2")
      }, 500)
    })
    .catch((err) => {
      history.push("/")
      doLogin()
    })
}

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
  isAuthExpired,
  updateRefreshToken,
}

export default UserService
