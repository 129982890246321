import React from "react"
import MoreHorizIcon from "@material-ui/icons/MoreHoriz"
import { IconButton, Menu, MenuItem, makeStyles } from "@material-ui/core"
import { classnames } from "@material-ui/data-grid"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles((theme) => ({
  menuIconButton: {
    "& .MuiSvgIcon-root": {
      fontSize: "35px",
    },
    "&:active": {
      color: "var(--primaryColor)",
    },
  },
  menu: {
    "& .MuiPaper-root": {
      width: "85px",
      border: "1px solid var(--primaryColor)",
    },

    "& .MuiList-padding": {
      padding: "0px !important",
    },
    "& .MuiListItem-root": {
      "&:hover": {
        background: "var(--bgColor)",
      },
    },
    "& .MuiListItem-root:first-child": {
      borderBottom: "1px solid var(--primaryColor)",
    },
  },
}))

function DotMenu(props) {
  const classes = useStyles()
  const { t } = useTranslation()
  const { list = [], onUpdate = () => { } } = props || {}
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (props) => {
    setAnchorEl(null)
    onUpdate(props)
  }
  return (
    <div>
      <IconButton
        style={{ padding: 0 }}
        aria-controls={open ? "long-menu" : undefined}
        onClick={handleClick}
        className={classes.menuIconButton}
        type="button"
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="simple-menu1-revenufilter"
        className={classes.menu}
        // id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {list.map((option) => (
          <MenuItem key={option.name} onClick={() => handleClose(option)}>
            {t(option.name)}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

export { DotMenu }
export default DotMenu
