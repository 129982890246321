import React, { useEffect, useState } from "react"
import { Box, CardMedia, Grid, IconButton, Typography } from "@material-ui/core"
import { DropzoneDialog } from "material-ui-dropzone"
import * as Adm from "@adm"
import { v4 as uuidv4 } from "uuid"
import CircularProgress from "@material-ui/core/CircularProgress"
import { Close } from "@material-ui/icons"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import { useTranslation } from "react-i18next"


const MediaUpload = ({ props, formUIProps }) => {
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false)
  const [selectedImages, setSelectedImages] = useState([])
  const [alert, setAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [alertType, setAlertType] = useState("")
  const { t } = useTranslation()

  useEffect(() => {
    setSelectedImages(formUIProps?.values?.item_images)
  }, [formUIProps?.values?.item_images])

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            {(formUIProps?.values?.item_images || [])?.map((o, i) => (
              <Grid item style={{ padding: "5px" }}>
                <Thumb
                  key={`image_thumbs_${i + 1}`}
                  image={o}
                  formUIProps={formUIProps}
                  i={i}
                  setSelectedImages={setSelectedImages}
                  props={props}
                />
              </Grid>
            ))}
            <Grid item style={{ padding: "5px" }}>
              <CardMedia
                style={{
                  height: "110px",
                  width: "130px",
                  cursor: props.disabled ? "" : "pointer",
                }}
                onClick={
                  props.disabled ? null : () => setIsUploadDialogOpen(true)
                }
                image="/static/img/avatars/plus23.jpg"
              />
            </Grid>
          </Grid>
        </Grid>
        <DropzoneDialog dropzoneClass="cls-uploadfiles"
          dialogTitle={<span className="cls-upload-title-kit">{t("Upload Files")}</span>}
          cancelButtonText={t("Cancel")}
          submitButtonText={t("Submit")}
          dropzoneText={t("Drag and drop a file here or click")}
          previewText={<span className="cls-upload-title-kit">{t("Preview")}</span>}
          getFileLimitExceedMessage={(filesLimit) => {
            return `${t("Maximum allowed number of files exceeded")}. ${filesLimit} ${t("files only allowed")}`
          }}
          getFileAddedMessage={(fileName) => {
            return `${t("File")} ${fileName} ${t("successfully added")}.`
          }}
          getFileRemovedMessage={(fileName) => {
            return `${t("File")} ${fileName} ${t("removed")}.`
          }}
          open={isUploadDialogOpen}
          onSave={async (e, f, g) => {
            setAlert(false)
            let fileFormatValidate = true
            let fileType = ""
            for (var i = 0; i < e.length; i++) {
              fileType = e[i].type
              if (
                fileType !== "image/tiff" &&
                fileType !== "image/webp" &&
                fileType !== "image/png" &&
                fileType !== "image/jpeg" &&
                fileType !== "image/jpg"
              ) {
                fileFormatValidate = false
              }
            }
            if (!fileFormatValidate) {
              await setAlertType("info")
              await setAlertMessage(
                t("Supported file formats .tiff, .webp, .PNG, .JPEG")
              )
              await setAlert(true)
            } else {
              const displayImages = []
              selectedImages?.forEach((selectedImage) => {
                displayImages.push(selectedImage)
              })
              e?.forEach((selectedImage) => {
                displayImages.push(selectedImage)
              })
              setSelectedImages((oldArray) => [...oldArray, ...e])
              formUIProps.setFieldValue("item_images", displayImages)
              setIsUploadDialogOpen(false)
            }
          }}
          showPreviews={true}
          maxFileSize={5242000}
          onClose={() => setIsUploadDialogOpen(false)}
          acceptedFiles={["image/jpg", "image/jpeg", "image/png", "image/tiff", "image/webp"]}
        />
      </Grid>
      {alert ? (
        <Adm.SnackBar open={true} message={alertMessage} type={alertType} />
      ) : null}
    </>
  )
}

export default MediaUpload

const useStyles = makeStyles((theme) => ({
  commonTxt: {
    position: "absolute",
    bottom: "5px",
    left: "5px",
    borderRadius: "4px",
    width: "100px",
    textAlign: "center",
    fontSize: "10px",
    height: "20px",
  },
  setAsDefault: {
    backgroundColor: "#1565C0",
    color: "#FFFF",
    cursor: "pointer",
    fontWeight: 100,
  },
  defaultTxt: {
    backgroundColor: "#E0E0E0",
    color: "#757575",
    width: "100px",
  },
  closeIcon: {
    color: "#ffff",
    background: "rgba(0, 0, 0, 0.17)",
    fontSize: "20px",
  },
}))

const Thumb = ({ key, image, formUIProps, i, setSelectedImages, props, }) => {
  const [imgAsUrl, setImgAsUrl] = useState("")
  const [alert, setAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [alertType, setAlertType] = useState("")
  const [tempArray, setTempArray] = useState(formUIProps?.values?.item_images)
  const classes = useStyles()
  const { t } = useTranslation()
  useEffect(() => {
    const tempArr = formUIProps?.values?.item_images
    if (tempArr && !tempArr.some((o) => o && o.default && o.default === true)) {
      tempArr[0].default = true
    }
    setTempArray(tempArr)
    setSelectedImages(tempArr)
    formUIProps.setFieldValue("item_images", tempArr)
    let index = ""
    tempArr.filter((x, idx) => {
      if (x.default && x.default === true) index = idx
    })
    formUIProps.setFieldValue("defaultImageIndex", index)
  }, [tempArray, formUIProps?.values?.item_images])

  useEffect(() => {
    if (image && image._id) {
      setImgAsUrl(image.url)
    } else if (image && image.type) {
      setImgAsUrl(URL.createObjectURL(image))
    }
  }, [image])

  const handleRemoveUploadedImage = (i) => {
    const tempArr = formUIProps?.values?.item_images
    const filteredImage = tempArr.filter((file, ind) => ind !== i)
    const deletedImages = []
    tempArr.forEach((x, idx) => {
      if (idx === i && x._id) {
        deletedImages.push(x.url)
      }
    })
    setTempArray([...filteredImage])
    setSelectedImages(filteredImage)

    formUIProps.setFieldValue("item_images", [...filteredImage])
    formUIProps.setFieldValue("deleted_images", deletedImages)
  }

  const handleSetDefaultImage = (i) => {
    const tempArr = formUIProps?.values?.item_images
    tempArr.map((o, idx) => {
      if (idx === i) {
        return (o.default = true)
      } else {
        return (o.default = false)
      }
    })
    moveArrayItemToNewIndex(tempArr, i, 0)
    setTempArray([...tempArr])
    setSelectedImages(tempArr)
    formUIProps.setFieldValue("item_images", [...tempArr])
  }

  const moveArrayItemToNewIndex = (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1
      while (k--) {
        arr.push(undefined)
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
    return arr
  }

  return (
    <>
      <Box style={{ position: "relative" }}>
        <CardMedia
          style={{ height: "110px", width: "130px" }}
          image={imgAsUrl}
        />
        {props.disabled === false && (
          <IconButton
            style={{
              position: "absolute",
              padding: "10px",
              top: "-4px",
              right: "-4px",
            }}
            onClick={() => handleRemoveUploadedImage(i)}
          >
            <Close className={classes.closeIcon} />
          </IconButton>
        )}
        <Typography
          className={clsx(classes.commonTxt, {
            [classes.defaultTxt]: tempArray[i].default === true,
            [classes.setAsDefault]:
              tempArray[i].default !== true && props.disabled === false,
          })}
          onClick={
            tempArray[i].default === true
              ? null
              : () => handleSetDefaultImage(i)
          }
        >
          {tempArray[i].default === true
            ? t("DEFAULT")
            : props.disabled === false
              ? t("SET AS DEFAULT")
              : ""}
        </Typography>
      </Box>
    </>
  )
}
