import React, { useState, useEffect } from "react"
import _, { isEqual } from "lodash"
import useStyles from "./styles"
import MaterialDataGrid from "../../../../components/MaterialDataGrid"
import locationDataApi from "../../../../services/masterData/locations/api"
import { productDataApiMethods } from "../../../../services/masterData/products/api"
import { Button, Typography } from "@material-ui/core"
import update from "immutability-helper"
import * as Adm from "@adm"
import LocationLookupFixture from "./LocationLookupFixture"
import ProductLookupFixture from "./ProductLookupFixture"
import KCLocationLookupFixture from "./KCLocationLookupFixture"
import POSLocationLookupFixture from "./POSLocationLookupFixture"
import { useTranslation } from "react-i18next"

const columnData = [
  {
    field: "locationName",
    title: "Location Name",
    editable: false,
    editor: "text",
    border: true,
    filter: true,
    show: true,
    min_width: 10,
  },
  {
    field: "locationAddress.line1",
    title: "Location Address",
    editable: false,
    editor: "text",
    border: true,
    filter: true,
    show: true,
    min_width: 15,
  },
  {
    field: "locationType.name",
    title: "Location Type",
    editable: false,
    editor: "text",
    border: true,
    filter: true,
    show: true,
    min_width: 15,
  },
]

const productColumnData = [
  {
    field: "title",
    title: "Title",
    editable: false,
    editor: "text",
    border: true,
    filter: true,
    show: true,
    min_width: 10,
  },
  {
    field: "barcodeValue",
    title: "Item Barcode Value",
    editable: false,
    editor: "text",
    border: true,
    filter: true,
    show: true,
    min_width: 12,
  },
  {
    field: "stockCode",
    title: "SKU",
    editable: false,
    editor: "text",
    border: true,
    filter: true,
    show: true,
    min_width: 17,
  },
]

const defaultDataState = {
  filter: null,
  group: undefined,
  sort: [],
  skip: 0,
  take: 10
}

const Table = ({
  isWriteAllowed,
  columns = null,
  hierarchyMapping = false,
  isLookUp = false,
  onPrimaryActionClick = () => { },
  preSelectedItems = [],
  gridNoRecordsText,
  triggerDataFetch = { uncategorized: false, categorized: false },
  setTriggerDataFetch = () => { },
  dataFetchTriggerKey = "uncategorized",
  title = "UnCategorized Locations",
  selectedHierarchyIds = [],
  fetchOnlyOnSelectedIdUpdate = false,
  dataUniqueIdPath = "_id",
  primaryActionLabel = "ASSOCIATE",
  additionalFilters = [
    { field: "locationHierarchy", operator: "eq", value: "" },
  ],
  lookupType = "location",
  hideCheckBoxAll = false,
  isInventory = false,
  searchObj = {},
  filterSelectedItems = {},
  SDCFilter = [],
  ShowCheckBox = true,
  isKC,
  locationBasedProduct = false,
  categoryType = "",
  setAnyChanges = () => { },
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [gridState, setGridState] = useState({ dataState: defaultDataState })
  const [selectedItems, setSelectedItems] = useState(
    () => preSelectedItems || []
  )
  const [rawData, setRawData] = useState({})
  const [gridData, setGridData] = useState({ data: [] })
  const [alert, setAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [alertType, setAlertType] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setSelectedItems(selectedItems)
  }, [selectedItems])

  useEffect(() => {
    if (!isLookUp) {
      handleClearSelection()
    }
    setGridState({ dataState: defaultDataState })
  }, [selectedHierarchyIds])

  const adopterFunction = (
    dataState,
    page,
    limit,
    additionalFilters = [],
    searchObj = {}
  ) => {
    let sort = {}
    if (isLookUp) {
      sort = dataState.sort.reduce((acc, o) => {
        acc[o.field] = o.dir
        return acc
      }, {})
      let cityFilter = []
      let stateFilter = []
      let brandFilter = []
      let manufacturerFilter = []
      let hierarchyFilterVal = ""
      let locTypeFilter = ""
      let locFilter = ""
      let locStoreTypeFilter = ""
      let posSearch = ""
      let productLoc = ""
      searchObj?.dropdown.map((o) => {
        if (lookupType === "location") {
          if (o.label === "City") {
            cityFilter = o.filteredItems.join()
          }
          if (o.label === "State") {
            stateFilter = o.filteredItems.join()
          }
        } else {
          if (o.label === "Brand") {
            brandFilter = o.filteredItems.join()
          }
          if (o.label === "Manufacturer") {
            manufacturerFilter = o.filteredItems.join()
          }
        }
      })
      additionalFilters.map((x) => {
        if (x.field === "locationType.name") {
          locTypeFilter = x.value
        }
        if (x.field === "locations.locationId") {
          locFilter = x.value
        }
        if (x.field === "storeType.name") {
          locStoreTypeFilter = x.value
        }
        if (x.field === "locationId") {
          productLoc = x
        }
        if (x.field === "locationHierarchy") {
          hierarchyFilterVal = x.value
        } else if (x.field === "product_mapped_category_id") {
          hierarchyFilterVal = x.value
        }
        if (x.field === "pos_search") {
          posSearch = x.value
        }
      })

      let tempFilter = {}
      let locBasedProdcFilter = []

      if (lookupType === "location") {
        tempFilter = {
          "locationAddress.city": cityFilter.split(","),
          "locationAddress.region": stateFilter.split(","),
          locationHierarchy: hierarchyFilterVal,
          "locationType.name": locTypeFilter,
          "storeType.name": locStoreTypeFilter,
        }
        if (cityFilter.length === 0) {
          delete tempFilter["locationAddress.city"]
        }
        if (stateFilter.length === 0) {
          delete tempFilter["locationAddress.region"]
        }
        if (hierarchyFilterVal === "") {
          delete tempFilter["locationHierarchy"]
        }
        if (locTypeFilter === "") {
          delete tempFilter["locationType.name"]
        }
        if (locStoreTypeFilter === "") {
          delete tempFilter["storeType.name"]
        }
        if (posSearch) {
          tempFilter["pos_search"] = posSearch
        }
      } else {
        if (locationBasedProduct) {
          locBasedProdcFilter = [productLoc]
        } else {
          tempFilter = {
            brand: brandFilter.split(","),
            manufacturer: manufacturerFilter.split(","),
            product_mapped_category_id: hierarchyFilterVal,
            "locations.locationId": locFilter,
            locationId: productLoc,
          }

          if (brandFilter.length === 0) {
            delete tempFilter["brand"]
          }
          if (manufacturerFilter.length === 0) {
            delete tempFilter["manufacturer"]
          }
          if (hierarchyFilterVal === "") {
            delete tempFilter["product_mapped_category_id"]
          }
          if (locFilter === "") {
            delete tempFilter["locations.locationId"]
          }
          if (productLoc === "") {
            delete tempFilter["locationId"]
          }
        }
      }
      return {
        page,
        limit,
        filter: locationBasedProduct ? locBasedProdcFilter : tempFilter,
        sort,
        searchTerm: searchObj?.searchTerm,
      }
    } else {
      sort = dataState.sort.reduce((acc, o) => {
        acc[o.field] = o.dir === "asc" ? 1 : -1
        return acc
      }, {})

      return {
        page,
        limit,
        filter: [
          ...(dataState?.filter?.filters || []),
          ...(additionalFilters || []),
        ],
        sort,
      }
    }
  }

  const fetchData = () => {
    setLoading(true)
    if (lookupType === "product") {
      if (locationBasedProduct) {
        let body = adopterFunction(
          gridState.dataState,
          Math.floor(gridState.dataState.skip / gridState.dataState.take) + 1,
          gridState.dataState.take,
          additionalFilters,
          searchObj
        )
        productDataApiMethods
          .getProductMapping(body)
          .then((resp) => {
            setRawData(_.get(resp, "data.data"))
            setLoading(false)
          })
          .catch((err) => {
            setLoading(false)
          })
      } else if (isLookUp) {
        productDataApiMethods
          .elasticSearchLocation(
            adopterFunction(
              gridState.dataState,
              Math.floor(gridState.dataState.skip / gridState.dataState.take) +
              1,
              gridState.dataState.take,
              additionalFilters,
              searchObj
            )
          )
          .then((resp) => {
            let tempDocs = []
            _.get(resp, "data.data.results").map((o) =>
              tempDocs.push({ ...o._source, _id: o._id })
            )
            let tempArr = {
              docs: tempDocs,
              totalDocs: _.get(resp, "data.data.total.value"),
            }
            setRawData(tempArr)
            setLoading(false)
          })
          .catch((err) => {
            setLoading(false)
          })
      } else {
        productDataApiMethods
          .getProducts(
            adopterFunction(
              gridState.dataState,
              Math.floor(gridState.dataState.skip / gridState.dataState.take) +
              1,
              gridState.dataState.take,
              additionalFilters
            )
          )
          .then((resp) => {
            setRawData(_.get(resp, "data.data"))
            setTriggerDataFetch((c) => ({
              ...c,
              [dataFetchTriggerKey]: false,
            }))
            setLoading(false)
          })
          .catch((err) => {
            setLoading(false)
          })
      }
    } else {
      if (isLookUp) {
        if (isKC) {
          locationDataApi
            .elasticSearchKCLocation(
              adopterFunction(
                gridState.dataState,
                Math.floor(
                  gridState.dataState.skip / gridState.dataState.take
                ) + 1,
                gridState.dataState.take,
                additionalFilters,
                searchObj
              )
            )
            .then((resp) => {
              let tempDocs = _.get(resp, "data.response").map((m) => ({
                ...m,
                _id: `${m._id}${m.kcpos_id}`,
              }))
              let tempArr = {
                docs: tempDocs,
                totalDocs: _.get(resp, "data.totalDocs"),
              }
              setRawData(tempArr)
              setLoading(false)
            })
            .catch((err) => {
              setLoading(false)
            })
        } else {
          locationDataApi
            .elasticSearchLocation(
              adopterFunction(
                gridState.dataState,
                Math.floor(
                  gridState.dataState.skip / gridState.dataState.take
                ) + 1,
                gridState.dataState.take,
                additionalFilters,
                searchObj
              )
            )
            .then((resp) => {
              let tempDocs = []
              _.get(resp, "data.data.results").map((o) =>
                tempDocs.push({
                  ...o._source,
                  _id: o._id,
                  posDetailCount: o._source?.posDetails?.length || 0,
                })
              )
              let tempArr = {
                docs: tempDocs,
                totalDocs: _.get(resp, "data.data.total.value"),
              }
              setRawData(tempArr)
              setLoading(false)
            })
            .catch((err) => {
              setLoading(false)
            })
        }
      } else {
        locationDataApi
          .getAllLocations(
            adopterFunction(
              gridState.dataState,
              Math.floor(gridState.dataState.skip / gridState.dataState.take) +
              1,
              gridState.dataState.take,
              additionalFilters
            )
          )
          .then((resp) => {
            setRawData(_.get(resp, "data.data"))
            setTriggerDataFetch((c) => ({
              ...c,
              [dataFetchTriggerKey]: false,
            }))
            setLoading(false)
          })
          .catch((err) => {
            setLoading(false)
          })
      }
    }
  }

  useEffect(() => {
    let dataFilter = gridState?.dataState?.filter?.filters || ""
    let filterCondition =
      dataFilter.length > 0 ||
      SDCFilter.length > 0 ||
      selectedHierarchyIds.length > 0 ||
      !isEqual(triggerDataFetch, { uncategorized: false, categorized: false })
    if (filterCondition) {
      fetchData()
    }
  }, [
    gridState.dataState,
    SDCFilter,
    triggerDataFetch[dataFetchTriggerKey],
    selectedHierarchyIds,
    isKC,
  ])

  useEffect(() => {
    setGridState((c) => ({ dataState: { ...c.dataState, skip: 0 } }))
  }, [
    filterSelectedItems.citySelectedItems,
    filterSelectedItems.stateSelectedItems,
    filterSelectedItems.brandSelectedItems,
    filterSelectedItems.manufacturerSelectedItems,
    filterSelectedItems.searchTerm,
  ])

  useEffect(() => {
    if (!_.isEmpty(rawData)) {
      setGridData(() => {
        let x = _.cloneDeep(_.get(rawData, "docs", []))
        let data = x.reduce((acc, o, i) => {
          if (
            _.findIndex(
              selectedItems,
              (c) =>
                _.get(o, dataUniqueIdPath, "*_*") ===
                _.get(c, dataUniqueIdPath, "-_-")
            ) !== -1
          ) {
            o.selected = true
          }
          acc.push(o)
          return acc
        }, [])
        return { data }
      })
    }
  }, [rawData])

  const handleSelection = (e) => {
    setAnyChanges(true)
    let temp = []
    let tempGridData = {}
    let dataItem = e.dataItem
    let existingIdx = _.findIndex(
      selectedItems,
      (o) =>
        _.get(o, dataUniqueIdPath, "*_*") ===
        _.get(dataItem, dataUniqueIdPath, "-_-")
    )
    let idxInDataState = _.findIndex(
      gridData.data,
      (o) =>
        _.get(o, dataUniqueIdPath, "*_*") ===
        _.get(dataItem, dataUniqueIdPath, "-_-")
    )
    if (existingIdx === -1) {
      temp = _.concat(dataItem, selectedItems)
      tempGridData = update(gridData, {
        data: { [idxInDataState]: { $merge: { selected: true } } },
        allSelected: { $set: false },
      })
    } else {
      temp = update(selectedItems, { $splice: [[existingIdx, 1]] })
      tempGridData = update(gridData, {
        data: { [idxInDataState]: { $merge: { selected: false } } },
        allSelected: { $set: false },
      })
    }
    if ((lookupType === "product" && isInventory) || hideCheckBoxAll) {
      setSelectedItems([])
      temp = []
      tempGridData.data.map((object, i) => {
        if (dataItem._id === tempGridData.data[i]._id) {
          tempGridData.data[i].selected = true
          update(selectedItems, { $push: [dataItem] })
          temp.push(dataItem)
        } else {
          tempGridData.data[i].selected = false
        }
      })
    }
    setSelectedItems(temp)
    setGridData(tempGridData)
  }

  const handleClearSelection = () => {
    setAnyChanges(false)
    setSelectedItems([])
    setGridData((c) => {
      return {
        data: c.data.map((x) => {
          x.selected = false
          return x
        }),
      }
    })
  }

  const onHeaderSelectionChange = React.useCallback(async (event) => {
    setAlert(false)
    setAnyChanges(true)
    const checkboxElement = event.syntheticEvent.target
    const checked = checkboxElement.checked
    if (lookupType === "product" && isInventory) {
      await setAlertType("error")
      await setAlertMessage(
        t(
          "More than one Product cannot be selected for inventory configuration"
        )
      )
      await setAlert(true)
    } else if (checked) {
      const newRowDataState = event.dataItems
      let newSelectedItems = selectedItems
      newRowDataState?.map((item) => {
        if (checked === true) {
          if (!_.find(newSelectedItems, { locationId: item.locationId })) {
            newSelectedItems.unshift(item)
          }
        } else {
          newSelectedItems = newSelectedItems.filter(function (item1) {
            return item1.locationId !== item.locationId
          })
        }
        return item
      })
      setGridData((c) => {
        let data = _.map(c?.data || [], (o, i) => {
          o.selected = true
          return o
        })
        setSelectedItems((s) => {
          let temp = _.isEmpty(s) ? [] : _.cloneDeep(s)
          var test = _.uniqBy([...temp, ...data], dataUniqueIdPath)
          var test1 = [...test].reverse()
          return _.uniqBy([...temp, ...data], dataUniqueIdPath)
        })
        return {
          allSelected: true,
          data,
        }
      })
    } else {
      setGridData((c) => {
        let data = c.data.map((x) => {
          x.selected = false
          return x
        })

        setSelectedItems((s) => {
          let filteredSelection = _.differenceBy(s, data, dataUniqueIdPath)
          return filteredSelection
        })
        return {
          data,
        }
      })
    }
  }, [])

  return (
    <div className={classes.container}>
      <Adm.BackdropOverlay open={loading} />
      <div className={`${classes.header} makeStyles-header-support`}>
        <Typography
          variant="h4"
          className={`${classes.title} makeStyles-title-support`}
        >
          {t(title)}
        </Typography>
        {hierarchyMapping ? (
          selectedItems.length > 0 || gridData?.allSelected || isLookUp ? (
            <HeaderActionsContainer
              t={t}
              isLookUp={isLookUp}
              onPrimaryActionClick={() =>
                onPrimaryActionClick(selectedItems, handleClearSelection)
              }
              count={
                selectedItems.length > 0
                  ? selectedItems.length
                  : gridData?.allSelected
                    ? gridData?.data?.length
                    : selectedItems.length
              }
              classes={classes}
              label={primaryActionLabel}
              handleClearSelection={handleClearSelection}
              selectedHierarchyIds={selectedHierarchyIds}
            />
          ) : null
        ) : selectedItems.length > 0 || gridData?.allSelected || isLookUp ? (
          <HeaderActionsContainer
            t={t}
            isLookUp={isLookUp}
            onPrimaryActionClick={() =>
              onPrimaryActionClick(selectedItems, handleClearSelection)
            }
            count={
              selectedItems.length > 0
                ? selectedItems.length
                : gridData?.allSelected
                  ? gridData?.data?.length
                  : selectedItems.length
            }
            classes={classes}
            label={primaryActionLabel}
            handleClearSelection={handleClearSelection}
          />
        ) : null}
      </div>
      <div className={classes.content}>
        <MaterialDataGrid
          isInlineEditAllowed={isWriteAllowed}
          columnData={
            columns ||
            (lookupType === "product"
              ? isLookUp
                ? ProductLookupFixture
                : productColumnData
              : isLookUp
                ? isKC
                  ? KCLocationLookupFixture
                  : LocationLookupFixture
                : columnData)
          }
          gridState={gridState}
          setGridState={setGridState}
          rowData={gridData}
          setRowData={setGridData}
          isLookup
          number_of_items={_.toFinite(rawData?.totalDocs || 0)}
          selectionChange={(event) => handleSelection(event)}
          otherProps={{ style: { height: "100%" } }}
          headerSelectionChange={onHeaderSelectionChange}
          buttonCount={1}
          gridNoRecordsText={gridNoRecordsText}
          checkBoxWidth={isLookUp ? "" : "30px"}
          hideCheckboxAll={hideCheckBoxAll}
          ShowCheckBox={ShowCheckBox}
        />
      </div>
      {alert ? (
        <Adm.SnackBar
          open={true}
          autoCloseTrigger={() => {
            setAlert(alert)
          }}
          message={alertMessage}
          type={alertType}
        />
      ) : null}
    </div>
  )
}

const HeaderActionsContainer = ({
  t,
  onPrimaryActionClick = () => { },
  classes = {},
  count = 0,
  label = "Primary Action",
  isLookUp = false,
  handleClearSelection = () => { },
}) => {
  return (
    <div
      className={`${classes.headerActionsContainer} makeStyles-headerActionsContainer-support`}
    >
      {count > 0 && (
        <>
          {" "}
          <Typography variant="h5" className={classes.subTitle}>
            {count} {count > 1 ? " " : " "}
            {t("Selected")}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: "var(--primaryColor)", color: "#FFF" }}
            disabled={isLookUp ? false : count === 0}
            onClick={onPrimaryActionClick}
          >
            {t(label)}
          </Button>
          <Button
            color="primary"
            style={{ fontWeight: "bold", color: "var(--primaryColor)" }}
            disableElevation
            onClick={handleClearSelection}
          >
            {t("Clear")}
          </Button>
        </>
      )}
    </div>
  )
}

export default Table
